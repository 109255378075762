/**
 * https://sowhat.atlassian.net/browse/LSWS-845
 */
import { Product } from '@shopware-pwa/types';

const useCustomPrices = (product: Product | any) => {
  // shopware composable
  const { getFormattedPrice } = usePrice();

  let listPrice: number | undefined = undefined;
  let price: number | undefined = undefined;

  // PLP and PDP
  if (product?.calculatedPrices?.length) {
    // WARNING: used first price rule of mutiple calculatedPrices[0],
    // graduated prices (Staffelpreis LSWS-845) not possible
    listPrice = product?.calculatedPrices?.[0]?.listPrice?.price;
    price = product?.calculatedPrices?.[0]?.unitPrice;
  } else {
    // no price rules set -> fallback to normal prices (calculatedPrice)
    listPrice = product?.calculatedPrice?.listPrice?.price;
    price = product?.calculatedPrice?.unitPrice;
  }

  // Offcanvas API gives us a bit differnt structure
  if (product?.priceDefinition) {
    listPrice = product?.priceDefinition?.listPrice;
    price = product?.price?.totalPrice || product?.priceDefinition?.price;
  }
  return {
    // left side of prices, if listPrice is set it is displayed
    // as strike price otherwise normal price
    regularPrice: () => {
      return getFormattedPrice(
        listPrice ? listPrice * (product?.quantity || 1) : price
      );
    },
    regularPriceRaw: () => {
      return listPrice ? listPrice * (product?.quantity || 1) : price;
    },

    // right side of prices, displayed as special price
    // otherwise it is empty
    specialPrice: () => {
      return getFormattedPrice(listPrice ? price : undefined);
    },
    specialPriceRaw: () => {
      return listPrice ? price : undefined;
    },
  };
};

export { useCustomPrices };
